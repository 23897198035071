import { Button } from '@/components/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { MdMode } from 'react-icons/md';
import { Challenge, User } from '@teamup/db';
import { trpc } from '@/lib/trpc';
import { Panel } from '@/components/ui/Panel.tsx';
import { Table } from '@/components/ui/elements/Table.tsx';
import { useState } from 'react';
import { Modal, useModal } from '@/components/ui/elements/Modal.tsx';
import { ChallengeForm } from '@/components/ui/forms/models/ChallengeForm.tsx';

export const Challenges = () => {
    const { data: challenges, isFetched, refetch } = trpc.challenge.list.useQuery({});

    const [challenge, setChallenge] = useState<Challenge | null>(null);
    const { isShowing, toggle } = useModal();

    const challengeHelper = createColumnHelper<Challenge>();
    const challengeColumns = [
        challengeHelper.accessor('display', {
            header: 'Display',
            cell: (info) => info.getValue(),
        }),
        challengeHelper.accessor('name', {
            header: 'Name',
            cell: (info) => info.getValue(),
        }),
        challengeHelper.accessor('order', {
            header: 'Order',
            cell: (info) => info.getValue(),
        }),
        challengeHelper.display({
            id: 'actions',
            header: 'Actions',
            cell: (info) => (
                <Button icon={<MdMode />} size="small" onClick={() => editChallenge(info.row.original)}>
                    Edit
                </Button>
            ),
        }),
    ];

    const editChallenge = (user: User): void => {
        setChallenge(user);
        toggle();
    };

    function newChallenge(): void {
        setChallenge(null);
        toggle();
    }

    function onClose() {
        toggle();
        refetch();
    }

    return (
        <>
            <Modal isShowing={isShowing} title={'Create/Edit User'}>
                <ChallengeForm challenge={challenge} onClose={toggle} />
            </Modal>

            <Panel header="Challenges">
                <Button icon={<MdMode />} size="small" onClick={() => newChallenge()}>
                    Create new
                </Button>
                {isFetched && <Table data={challenges} columns={challengeColumns} />}
            </Panel>
        </>
    );
};
