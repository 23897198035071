import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, InputField } from '@/components/ui';
import { Challenge, ChallengePartial, ChallengePartialSchema, ChallengeSchema, Company } from '@teamup/db';
import { trpc } from '@/lib/trpc';
import { zodResolver } from '@hookform/resolvers/zod';

export interface ChallengeFormProps {
    challenge: ChallengePartial;
    onClose?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ChallengeForm = ({
                                  challenge, onClose = () => {
    }
                              }: ChallengeFormProps) => {
    const mode: 'edit' | 'create' = challenge !== null ? 'edit' : 'create';

    const { register, handleSubmit, formState: {errors} } = useForm<Challenge>({ resolver: zodResolver(ChallengePartialSchema) });

    const createMutate = trpc.challenge.create.useMutation();
    const updateMutate = trpc.challenge.update.useMutation();

    const onSubmit: SubmitHandler<ChallengePartial> = (data: ChallengePartial) => {
        if (mode === 'create') {
            createMutate.mutateAsync({data}).then(() => onClose());
        } else if (mode === 'edit') {
            updateMutate.mutateAsync({ where: { id: challenge.id }, data }).then(() => onClose());
        }
    };

    // @ts-ignore
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-2">
            <label>
                Display
                <InputField type="text" {...register('display', { required: true })} defaultValue={challenge?.display} />
                {errors.display?.message}
            </label>
            <label>
                Name
                <InputField type="text" {...register('name', { required: true })} defaultValue={challenge?.name} />
                {errors.name?.message}
            </label>
            <label>
                Order
                <InputField type="number" {...register('order', {
                    valueAsNumber: true,
                    required: true
                })} defaultValue={challenge?.order} />
                {errors.order?.message}

            </label>
            {JSON.stringify(errors)}
            <div className="flex justify-end">
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type="submit">Save</Button>
            </div>

            {/*<DevTool control={control} />*/}
        </form>
    );
};
