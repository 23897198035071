import { Button } from '@/components/ui';
import { Panel } from '@/components/ui/Panel';
import { Link } from 'react-router-dom';

export const AdminLayout = () => {


    return (
        <Panel className="flex flex-col gap-y-4" header="Admin">
            <Link to="/dashboard/admin/challenges">
                <Button>Challenges</Button>
            </Link>
            <Link to="/dashboard/admin/users">
                <Button>Users</Button>
            </Link>
            <Link to="/dashboard/admin/companies">
                <Button>Companies</Button>
            </Link>
            <Link to="/dashboard/admin/gameservers">
                <Button>Gameservers</Button>
            </Link>
            <Link to="/dashboard/admin/sessions">
                <Button>Sessions</Button>
            </Link>
        </Panel>
    );
};
